// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-estandares-js": () => import("./../../../src/pages/estandares.js" /* webpackChunkName: "component---src-pages-estandares-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-manual-js": () => import("./../../../src/pages/manual.js" /* webpackChunkName: "component---src-pages-manual-js" */),
  "component---src-pages-new-hampshire-js": () => import("./../../../src/pages/new-hampshire.js" /* webpackChunkName: "component---src-pages-new-hampshire-js" */),
  "component---src-pages-plymouth-rock-barreada-js": () => import("./../../../src/pages/plymouth-rock-barreada.js" /* webpackChunkName: "component---src-pages-plymouth-rock-barreada-js" */),
  "component---src-pages-plymouth-rock-blanca-js": () => import("./../../../src/pages/plymouth-rock-blanca.js" /* webpackChunkName: "component---src-pages-plymouth-rock-blanca-js" */),
  "component---src-pages-plymouth-rock-js": () => import("./../../../src/pages/plymouth-rock.js" /* webpackChunkName: "component---src-pages-plymouth-rock-js" */),
  "component---src-pages-rhode-island-js": () => import("./../../../src/pages/rhode-island.js" /* webpackChunkName: "component---src-pages-rhode-island-js" */)
}

